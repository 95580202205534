// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    text-align: center;
    padding: 10px;
    color: #520e05;
  }
  
  .footerTitle {
    font-size: 16px;
    margin: 0;
  }
  
  .footerTitle strong a {
    text-decoration: none;
    color: #520e05;
  }
  
  .footerTitle strong a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".footer {\n    text-align: center;\n    padding: 10px;\n    color: #520e05;\n  }\n  \n  .footerTitle {\n    font-size: 16px;\n    margin: 0;\n  }\n  \n  .footerTitle strong a {\n    text-decoration: none;\n    color: #520e05;\n  }\n  \n  .footerTitle strong a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
