// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 81vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  
.home-title {
    font-size: 100px;
    font-family: 'Apple Chancery', sans-serif;
    font-style: oblique;
    padding: 20px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    border: 2px solid;
    border-radius: 10px;
    /* color: #520e05; */
}

@media (max-width: 768px) {
  .home-container {
    margin-top: -50px;
    padding-top: 0;
  }

  .home-title {
    font-size: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,4BAA4B;IAC5B,2BAA2B;IAC3B,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;EAClB;;AAEF;IACI,gBAAgB;IAChB,yCAAyC;IACzC,mBAAmB;IACnB,aAAa;IACb,0CAA0C;IAC1C,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;EACE;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".home-container {\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-attachment: fixed;\n    background-position: center;\n    height: 81vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    overflow: hidden;\n  }\n  \n.home-title {\n    font-size: 100px;\n    font-family: 'Apple Chancery', sans-serif;\n    font-style: oblique;\n    padding: 20px;\n    /* background-color: rgba(0, 0, 0, 0.5); */\n    border: 2px solid;\n    border-radius: 10px;\n    /* color: #520e05; */\n}\n\n@media (max-width: 768px) {\n  .home-container {\n    margin-top: -50px;\n    padding-top: 0;\n  }\n\n  .home-title {\n    font-size: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
