// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 87vh;
    padding: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .contact-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .contact-person {
    flex: 1 1;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .contact-name {
    font-size: 24px;
    margin: 0;
    color: white;
  }
  
  .contact-phone {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .winery-address {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .address-title {
    font-size: 24px;
    margin: 0;
    color: white;
  }
  
  .real-address,
  .email-address {
    font-size: 16px;
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Contact.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,4BAA4B;IAC5B,2BAA2B;IAC3B,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,oCAAoC;EACtC;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,SAAO;IACP,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,SAAS;IACT,YAAY;EACd;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,SAAS;IACT,YAAY;EACd;;EAEA;;IAEE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".contact-container {\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-attachment: fixed;\n    background-position: center;\n    height: 87vh;\n    padding: 20px;\n    color: white;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n  \n  .contact-title {\n    font-size: 36px;\n    margin-bottom: 20px;\n  }\n  \n  .contact-info {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  }\n  \n  .contact-person {\n    flex: 1;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 20px;\n    border-radius: 10px;\n    text-align: center;\n  }\n  \n  .contact-name {\n    font-size: 24px;\n    margin: 0;\n    color: white;\n  }\n  \n  .contact-phone {\n    font-size: 16px;\n    margin-top: 10px;\n  }\n  \n  .winery-address {\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 20px;\n    border-radius: 10px;\n    text-align: center;\n  }\n  \n  .address-title {\n    font-size: 24px;\n    margin: 0;\n    color: white;\n  }\n  \n  .real-address,\n  .email-address {\n    font-size: 16px;\n    margin-top: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
