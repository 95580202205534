// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../slike/pozadina1.jpeg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../slike/pozadina2.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../slike/pozadina3.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../slike/pozadina4.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 }

 body {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  transition: background-image 1s ease-in-out;
  margin: 0;
  padding: 0;
  font-size: 1rem;
}


body.image-1 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

body.image-2 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

body.image-3 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

body.image-4 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}


@media (max-width: 768px) {
  body {
    font-size: 1rem;
  }

  
  .header-container {
    height: 60px; 
    padding: 15px;
  }

  .nav-list {
    justify-content: center; 
  }

  
  body {
    background-size: 120%;
  }
}

@media (max-width: 480px) {
  body {
    font-size: 0.875rem;
  }

  .header-container {
    height: 50px;
    padding: 10px;
  }

  .logo {
    display: none;
  }

  .nav-list {
    flex-direction: column;
    justify-content: space-around;
  }

  body {
    background-size: 150%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;CACvB;;CAEA;EACC,yDAAgD;EAChD,sBAAsB;EACtB,4BAA4B;EAC5B,4BAA4B;EAC5B,2BAA2B;EAC3B,2CAA2C;EAC3C,SAAS;EACT,UAAU;EACV,eAAe;AACjB;;;AAGA;EACE,yDAAgD;AAClD;;AAEA;EACE,yDAA+C;AACjD;;AAEA;EACE,yDAA+C;AACjD;;AAEA;EACE,yDAA+C;AACjD;;;AAGA;EACE;IACE,eAAe;EACjB;;;EAGA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,uBAAuB;EACzB;;;EAGA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,6BAA6B;EAC/B;;EAEA;IACE,qBAAqB;EACvB;AACF","sourcesContent":["*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n }\n\n body {\n  background-image: url('../slike/pozadina1.jpeg');\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n  background-position: center;\n  transition: background-image 1s ease-in-out;\n  margin: 0;\n  padding: 0;\n  font-size: 1rem;\n}\n\n\nbody.image-1 {\n  background-image: url('../slike/pozadina1.jpeg');\n}\n\nbody.image-2 {\n  background-image: url('../slike/pozadina2.png');\n}\n\nbody.image-3 {\n  background-image: url('../slike/pozadina3.jpg');\n}\n\nbody.image-4 {\n  background-image: url('../slike/pozadina4.jpg');\n}\n\n\n@media (max-width: 768px) {\n  body {\n    font-size: 1rem;\n  }\n\n  \n  .header-container {\n    height: 60px; \n    padding: 15px;\n  }\n\n  .nav-list {\n    justify-content: center; \n  }\n\n  \n  body {\n    background-size: 120%;\n  }\n}\n\n@media (max-width: 480px) {\n  body {\n    font-size: 0.875rem;\n  }\n\n  .header-container {\n    height: 50px;\n    padding: 10px;\n  }\n\n  .logo {\n    display: none;\n  }\n\n  .nav-list {\n    flex-direction: column;\n    justify-content: space-around;\n  }\n\n  body {\n    background-size: 150%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
